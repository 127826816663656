<template>
  <div id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="isDisplay" v-show="$route.meta.isShow">
        <div class="top">
          <a-form layout="inline">
            <a-form-item>
              <a-button icon="plus" class="margin_right20" type="primary" @click="addClick">添加</a-button>
              <a-button class="margin_right20" type="primary" @click="switchClick">是否显示申怡佳选：{{ isSize == 1 ? '是' : '否' }}</a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- 表格 -->
        <a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id"
          :loading="tableLoading">
          <span slot="imageUrl" slot-scope="imageUrl, record">
            <img v-viewer style="width: 50px" :src="record.imageUrl" alt="封面图" />
          </span>
          <span slot="livestream" slot-scope="livestream, record">
            <span>{{ record.livestream === 1 ? '申怡读书' : '申怡佳选' }}</span>
          </span>
          <span slot="operation" slot-scope="operation, record, index">
            <a class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
            <a-popconfirm title="确认要删除此直播吗？" ok-text="确定" cancel-text="取消" @confirm="deleteClick(record, index)">
              <a type="primary">删除</a>
            </a-popconfirm>
          </span>
        </a-table>
        <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading" />
        <!-- 返回顶部 -->
        <a-back-top :target="targetFn" :visibilityHeight="100" />
      </div>
    </a-spin>
    <router-view />

    <a-modal v-model="visibleShow" :title="titleSize" @ok="handleOk" @cancel="handleCancel" style="height: 500px">
      <a-form-model v-if="visibleShow" ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item ref="daysTime" label="直播时间" prop="daysTime">
          <a-date-picker v-model="form.daysTime" format="YYYY-MM-DD HH:mm:ss" showTime placeholder="请选择直播时间"
            :disabled="isDisable" @change="daysChange" style="width: 100%" />
        </a-form-model-item>
        <a-form-model-item ref="livestream" label="小程序" prop="livestream">
          <a-radio-group v-model="form.livestream" name="radioGroup">
            <a-radio :value="1">申怡读书</a-radio>
            <a-radio :value="2">申怡佳选</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item ref="presentImg" label="直播介绍图" prop="presentImg">
          <UpdataImg @avatarfns="presentFn" :imageUrlNum="1" :imgUrl="presentImg" :urlSize="500"></UpdataImg>
          <span>推荐尺寸180*180</span>
          <br>
          <span v-if="isErrorPresent" style="color:red">请上传直播介绍图</span>
        </a-form-model-item>
        <a-form-model-item ref="descb" label="直播标题" prop="descb">
          <a-textarea v-model="form.descb" placeholder="请输入直播标题" :rows="4" show-count :maxlength="255"
            style="width: 100%" />
          <p style="width:100%;text-align: right;margin-top:-8px">{{ form.descb.length }}/255</p>
        </a-form-model-item>
        <a-form-model-item ref="billImg" label="分享海报" prop="billImg">
          <UpdataImg @avatarfns="billFn" :imageUrlNum="1" :imgUrl="billImg" :urlSize="500"></UpdataImg>
          <span>推荐尺寸600*1050</span>
          <span v-if="isErrorBill" style="color:red">请上传分享海报</span>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import UpdataImg from "@/components/file/updataImg";
import MyPagination from "@/components/pagination/MyPagination";
import { liveDescAdd, liveDescDelete, liveDescList, liveDescUpdate, getShowSwitch, isShowSwitchL } from "@/request/api/appletsManage";
export default {
  components: { MyPagination, UpdataImg },
  created () {
    this.getInitFn();
    this.ShowSwitch()
  },
  data () {
    return {
      isSize: -1,
      visibleShow: false,
      isShowAddOrEdit: false,
      isShowStatus: false,

      tip: "",
      titleSize: '',
      isDisable: false,
      tableLoading: true,
      spinning: false, // 全局loading
      pageNo: 1,
      pageSize: 20,
      count: 0,

      columns: [
        {
          title: "直播间",
          dataIndex: "livestream",
          key: "livestream",
          scopedSlots: { customRender: "livestream" },
        },
        {
          title: "直播时间",
          dataIndex: "days",
          key: "days",
        },
        {
          title: "直播介绍图",
          dataIndex: "imageUrl",
          key: "imageUrl",
          scopedSlots: { customRender: "imageUrl" },
        },
        {
          title: "直播标题",
          dataIndex: "descb",
          key: "descb",
        },
        {
          title: "操作",
          width: 150,
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      presentImg: [],
      isErrorPresent: false,
      billImg: [],
      isErrorBill: false,
      form: {
        id: '',
        daysTime: '',
        livestream: 0,
        descb: '',
        days: '',
        shareImageUrl: '',
        imageUrl: '',
      },

      rules: {
        daysTime: [{ required: true, message: "请选择直播时间", trigger: "change" }],
        livestream: [{ required: true, message: "请选择小程序", trigger: "change" }],
        descb: [{ required: true, message: "请输入直播标题", trigger: "blur" }],
        billImg: [{ required: true, message: "" }],
        presentImg: [{ required: true, message: "" }],
      },
    };
  },

  watch: {
    $route (to) {
      if (to.path === "/appletsManage/liveIntroduce") {
        this.getInitFn();
      }
    },
  },

  methods: {
    ShowSwitch () {
      getShowSwitch().then(({ code, data }) => {
        this.isSize = data*1
      });
    },

    switchClick () {
      let switchStatus = !this.isSize ? '1' : '0'
      isShowSwitchL({
        switch: switchStatus
      }).then(({ code, data }) => {
        this.isSize = !this.isSize
      });
    },

    presentFn (data) {
      this.presentImg = data
    },
    billFn (data) {
      this.billImg = data
    },

    daysChange (date, dateString) {
      if (dateString != "") {
        this.form.days = dateString;
      } else {
        this.form.days = "";
      }
    },
    // 点击添加按钮
    addClick () {
      this.isDisable = false
      this.visibleShow = true
      this.titleSize = '新增'
    },

    // 点击编辑按钮
    editClick (record) {
      this.isDisable = true
      this.form.daysTime = record.days
      this.form.days = record.days
      this.form.id = record.id
      this.form.descb = record.descb
      this.form.livestream = record.livestream
      this.billImg = record.shareImageUrl.split(',')
      this.presentImg = record.imageUrl.split(',')
      this.titleSize = '编辑'
      this.visibleShow = true
    },

    // 分页功能切换的回调
    showSizeChangeFn (current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getInitFn();
    },

    // 原属数据获取
    getInitFn () {
      liveDescList({
        pageSize: this.pageSize,
        pageNo: this.pageNo
      }).then(({ code, data }) => {
        this.tableLoading = false
        this.tableData = data.list;
        this.count = data.count
      });
    },

    handleOk () {
      let that = this
      if (that.presentImg.length > 0) {
        that.isErrorPresent = false
      } else {
        that.isErrorPresent = true
        return false
      }

      if (that.billImg.length > 0) {
        that.isErrorBill = false
      } else {
        that.isErrorBill = true
        return false
      }

      that.form.imageUrl = that.presentImg.toString()
      that.form.shareImageUrl = that.billImg.toString()
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (that.form.id) {
            liveDescUpdate(that.form).then((res) => {
              if (res.code == 200) {
                that.$message.success("新增成功");
                that.getInitFn()
                that.nullFun()
                that.visibleShow = false
              }
            });
          } else {
            liveDescAdd(that.form).then((res) => {
              if (res.code == 200) {
                that.$message.success("新增成功");
                that.getInitFn()
                that.nullFun()
                that.visibleShow = false
              }
            });
          }
        }
      });
    },

    handleCancel () {
      this.nullFun()
    },

    nullFun () {
      this.form.id = ''
      this.form.days = ''
      this.form.descb = ''
      this.form.daysTime = ''
      this.form.livestream = 0
      this.form.imageUrl = ''
      this.form.shareImageUrl = ''
      this.billImg = []
      this.presentImg = []
    },

    // 删除介绍
    deleteClick (record, index) {
      liveDescDelete({
        id: record.id,
      }).then(({ code, data }) => {
        if (code == 200) {
          this.tableData.splice(index, 1)
          this.$message.success('已删除')
          this.getInitFn()
        } else {
          this.$message.success(data.msg)
        }
      });
    },

    // 回到顶部的函数
    targetFn () {
      return document.querySelector("#third_Party_order");
    },
  },
};
</script>

<style lang="less" scoped>
#third_Party_order {
  height: 100%;
  overflow: hidden;
}

.avatar {
  width: 50px;

  img {
    width: 100%;
  }
}

.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;

    span {
      margin-right: 20px;

      /deep/ .ant-input {
        width: 220px;
      }

      /deep/ .ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }

      /deep/ .ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }

      /deep/ .ant-calendar-picker {
        width: 400px !important;
      }
    }

    .ant-btn {
      margin-right: 20px;
    }
  }
}</style>
